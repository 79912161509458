import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Channel } from '@Types/channel/Channel';
import { GrClose } from 'react-icons/gr';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import Icon from 'components/icons/infoIcon';
import MegaMenuArrow from 'components/images/megamenu-arrow.png';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useAccount, fetchApiHub, revalidateOptions } from 'frontastic';
import SearchIcon from '../../../icons/search';
import StarIcon from '../../../icons/star-icon-blue';
import QuantityDropdown from '../cart/QuantityDropdown';
import PdpQuantityDropdown from '../pdp/product-preview/pdpQuantityDropdown';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
interface Props {
  closeModal: any;
  data: any;
  isPDP?: boolean;
  handleAddToCart?: any;
  quantity?: number;
  setQuantity?: React.Dispatch<React.SetStateAction<number>>;
  maxQuantity?: number;
  itemQty?: number;
  isItemNotAvailbleToSelectedStore?: boolean;
}

const ChangeStore = ({
  closeModal,
  data,
  isPDP = false,
  handleAddToCart,
  quantity,
  setQuantity,
  maxQuantity,
  itemQty,
  isItemNotAvailbleToSelectedStore,
}: Props) => {
  const { listingStores, getChannelsSearch, account } = useAccount();
  const { updateItem, updateLineItemShippingDetails, getStorePriceGWP } = useCart();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItem(lineItemId, newQuantity);
  const [currentStoreList, setCurrentStoreList] = useState<Channel[]>([]);
  const [allStores, setAllStores] = useState<Channel[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStore, setSelectedStore] = useState<Record<any, any>>({});
  const router = useRouter();
  const location = router?.asPath;
  const [pickUp, setPickUp] = useState(false);
  const [storePriceData, setStorePriceData] = useState(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [maxQuantityForDropDown, setMaxQuantityForDropDown] = useState<number>(maxQuantity);
  const [zipError, setZipError] = useState('');
  const [dropdownSelectedValue, setDropdownSelectedValue] = useState(data?.count);
  const isBOPISItem = data?.variant?.attributes?.isPickupInStore && !data?.variant?.attributes?.isShipToAddress;

  const StoreAddress = ({ address }) => {
    let line1 = '';
    let line2 = '';
    if (address?.streetName) {
      line1 = line1 + address.streetName + ', ';
    }
    if (address?.streetNumber) {
      line1 = line1 + address.streetNumber + ', ';
    }
    if (address?.city) {
      line2 = line2 + address.city + ', ';
    }
    if (address?.state) {
      line2 = line2 + address.state + ' ';
    }
    if (address?.postalCode) {
      line2 = line2 + address.postalCode;
    }
    return (
      <>
        {line1} <br /> {line2}
      </>
    );
  };

  const ExtendedPickupMessage = () => {
    const queryParams = new URLSearchParams({
      messageKey: 'extendedPickupMessage',
    });
    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return (
      <section>
        <p className="pl-1">{data}</p>
      </section>
    );
  };

  const queryParams = new URLSearchParams({
    messageKey: 'availableStoreForBOPIS',
  });
  const { data: availableStoreForBOPISMsg } = useSWR(
    `/action/customObjects/getCustomMessage?${queryParams}`,
    fetchApiHub,
    revalidateOptions,
  );

  const StoreTile = ({ store }) => {
    const [extendedPickupMessages, setExtendedPickupMessages] = useState([]);

    const handleMouseEnter = (index) => {
      setExtendedPickupMessages({ ...extendedPickupMessages, [index]: true });
    };

    const handleMouseLeave = (index) => {
      setExtendedPickupMessages({ ...extendedPickupMessages, [index]: false });
    };
    return (
      <section>
        <section className="relative flex justify-between">
          <div className="w-1/2">
            <h4 className="mb-2 cursor-pointer text-affes-sm font-bold leading-5 text-blue-900">{store?.name}</h4>
          </div>

          <section className="absolute right-0 flex max-w-[116px] text-right md:max-w-full">
            <p
              className={`${
                store?.isOnStock && store?.isShipToEligible
                  ? 'mb-3 text-[13px] font-bold leading-5 text-green-900'
                  : 'mb-3 text-[13px] font-bold leading-5 text-red-900'
              }`}
            >
              {store?.isShipToEligible
                ? store?.isOnStock
                  ? `${store?.quantity} ${
                      store?.isOnStockShipToStore
                        ? formatCartMessage({
                            id: 'shipToStore',
                            defaultMessage: 'Ship to Store',
                          })
                        : formatCartMessage({
                            id: 'availablePickup',
                            defaultMessage: 'Available for Pickup',
                          })
                    }`
                  : `${formatCartMessage({
                      id: 'itemNoAvailable',
                      defaultMessage: 'Item not available at this Store',
                    })}`
                : `${formatCartMessage({
                    id: 'itemNoAvailable',
                    defaultMessage: 'Item not available at this Store',
                  })}`}{' '}
              {store?.isShowExtendedPickupMessage && (
                <div className="relative">
                  <span>
                    {' '}
                    {formatCartMessage({ id: 'receiveGiftCard', defaultMessage: '- Plus receive free gift card' })}
                    <button
                      type="button"
                      aria-label="Show Icon Eligible for incentive Gift Card"
                      className="relative inline text-[13px] font-bold"
                      onMouseLeave={() => handleMouseLeave(store.index)}
                      onMouseEnter={() => handleMouseEnter(store.index)}
                      onTouchStart={() => handleMouseEnter(store.index)}
                      onTouchEnd={() => handleMouseLeave(store.index)}
                    >
                      <Icon className="relative top-[2px] ml-1 text-black-400" />
                      {extendedPickupMessages[store.index] && (
                        <div>
                          <section className="boxArrow rotate- absolute -left-[1px] top-[18px]  ">
                            <img src={MegaMenuArrow.src} alt="Mega Menu Arrow Icon" className="" />
                          </section>
                          <div className="absolute top-8 right-0 z-30 w-[178px] rounded border-1  border-[#dadada] bg-white text-left text-sm font-normal text-gray-900">
                            <div className="relative z-[1] flex rounded-t-md border-b border-solid bg-[#EDEDED] px-1 text-base font-bold after:absolute after:-top-[1px] after:right-[6px] after:inline-block after:h-[1px] after:w-[23px] after:bg-white after:content-[''] sm:after:right-[8px]">
                              <p>Details</p>
                            </div>
                            <ExtendedPickupMessage />
                          </div>
                        </div>
                      )}
                    </button>
                  </span>
                </div>
              )}
            </p>
          </section>
        </section>

        <section className="flex justify-between">
          <section className="flex flex-col">
            <p className="w-[12rem] text-affes-sm leading-5 text-gray-900">
              {store?.address && <StoreAddress address={store.address} />}
            </p>
            {store?.isCurbSidePickupEligible ? (
              <p className="mt-0.5 text-affes-sm text-green-900">Curbside available</p>
            ) : (
              <></>
            )}
          </section>
        </section>
        <section className="mt-4 flex justify-between">
          <section className="flex w-[24rem]">
            <p className="mr-5 cursor-pointer text-affes-sm leading-5 text-blue-900">
              <a href={`tel:${store?.phoneNumber}`}>
                <span>{store?.phoneNumber}</span>
              </a>
            </p>{' '}
            <p className="cursor-pointer text-affes-sm leading-5 text-blue-900">
              <Link href={`https://www.google.com/maps/dir/?api=1&destination=${store?.lat},${store.long}`}>
                <a target="_blank" rel="noopener noreferrer">
                  <span>
                    {formatAccountMessage({
                      id: 'getDirections',
                      defaultMessage: 'Get Directions',
                    })}
                  </span>
                </a>
              </Link>
            </p>
          </section>
          <section className="w-[60%] text-right">
            <p className="text-affes-sm leading-5 text-gray-900">
              {store.distance} {formatCommonMessage({ id: 'miles', defaultMessage: 'Miles' })}
            </p>
          </section>
        </section>
        {/* <p className="mt-4 flex items-center text-affes-base leading-5 text-gray-900">
          <img src={GiftCardIcon.src} alt="Gift Card" className="mr-3 h-6 w-8" /> Receive a $25 Gift card
        </p> */}
      </section>
    );
  };

  const selectStoreForPickup = async (channelKey: string) => {
    if (isPDP) {
      await handleAddToCart({ isPickupInStore: true, channel: channelKey });
    } else {
      await updateLineItemShippingDetails({
        lineItemId: data.lineItemId,
        channelKey: channelKey,
        count: dropdownSelectedValue,
      });
    }
    closeModal();
  };

  const productID = data?.variant?.sku;

  const searchStores = async () => {
    if (searchQuery.length > 3) {
      setIsLoad(true);
      const searchList = await getChannelsSearch(searchQuery, productID);
      if (searchList) {
        setCurrentStoreList(searchList);
        setZipError('');
      }
    } else if (searchQuery.length <= 3 && searchQuery.length > 0) {
      setZipError('Search Input should have more than 3 characters');
      setIsLoad(false);
    } else {
      setZipError('Search Input is required');
      setIsLoad(false);
    }
    setIsLoad(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchStores();
    }
  };

  const isCTADisabled = useMemo(() => {
    if (
      selectedStore?.key &&
      selectedStore?.isOnStock &&
      storePriceData?.extPrice?.centAmount > 0 &&
      selectedStore?.isShipToEligible
    )
      return false;
    return true;
  }, [selectedStore]);

  useEffect(() => {
    const fetchChannelList = async () => {
      setIsLoad(true);
      const channelList = await listingStores(productID);
      setAllStores(channelList);
      setCurrentStoreList(channelList);
      setIsLoad(false);
    };
    allStores.length == 0 && fetchChannelList();
  }, []);

  useEffect(() => {
    if (location.indexOf('cart') >= 0) {
      setPickUp(true);
    } else {
      setPickUp(false);
    }
  });
  const getStorePriceForPickup = async (store) => {
    if (store?.key === selectedStore?.key) return;
    setIsLoad(true);
    if (store?.quantity > 0) {
      try {
        const sku = data?.variant?.sku;
        const response = await getStorePriceGWP(sku, store?.key);
        setStorePriceData(response);
      } catch (error) {
        console.error('Error fetching store price:', error);
      }
    }
    setSelectedStore(store);
    setIsLoad(false);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setCurrentStoreList(allStores);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedStore?.key) {
      /*
  
        if 
        store QTY is less than 1, then set max QTY to 1
        else
            if  
            store QTY is more than Product max QTY, then set max QTY to Product max QTY
            else
            set max QTY to store QTY
  
      */

      const temMaxQuantity =
        selectedStore?.quantity > 0
          ? selectedStore?.quantity > maxQuantity
            ? maxQuantity
            : selectedStore?.quantity
          : 1;
      setMaxQuantityForDropDown(temMaxQuantity);
      if (selectedStore?.quantity < quantity) setQuantity(1);
      if (temMaxQuantity < data?.count) setDropdownSelectedValue(1);
    }
  }, [selectedStore]);
  return (
    <>
      {isLoad && <Loader />}
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                // enter="ease-out duration-300" //This line causes a popup to open up with a glitch in the PDP .
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={closeModal}
                  aria-labelledby="changeStoreDialogTitle"
                  role="dialog"
                  aria-modal="true"
                >
                  <section
                    className="hide-scrollbar absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[20px] pt-8  dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={handleKeyDown}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section className="modalHeader sticky top-0 z-10 mb-3 bg-white">
                        <button
                          type="button"
                          aria-label="Close Modal"
                          className="absolute right-0 top-0"
                          onClick={() => closeModal(false)}
                        >
                          <GrClose />
                        </button>
                        <h3
                          id="changeStoreDialogTitle"
                          className="mb-[11px] text-[19px] font-bold leading-[21.6px] text-gray-900 "
                        >
                          {formatCartMessage({ id: 'changeStore', defaultMessage: 'Change Store' })}
                        </h3>
                        <section className="mb-[27px]">
                          <section className="flex justify-between">
                            <section className="flex">
                              <section className="mr-3">
                                <Link href={data?._url}>
                                  <p className="cursor-pointer">
                                    <img src={data?.variant?.images[0]} className="w-20" alt="Change Store Icon" />
                                  </p>
                                </Link>
                              </section>

                              <section className="w-2/3">
                                <Link href={data?._url}>
                                  <p className="mb-2 cursor-pointer text-affes-sm font-bold leading-[20px] text-blue-900">
                                    {data?.name}
                                  </p>
                                </Link>
                                <p className="mb-3 text-affes-sm font-semibold leading-[20px] text-gray-800">
                                  {' '}
                                  {formatCommonMessage({ id: 'item', defaultMessage: 'item' })} {data?.variant?.sku}
                                </p>
                                <p>
                                  {selectedStore?.isOnStock && storePriceData?.extPrice?.centAmount !== 0 && (
                                    <Price
                                      price={
                                        storePriceData?.extPrice?.centAmount !== 0 ? storePriceData?.extPrice : null
                                      }
                                      className="text-affes-sm font-bold leading-[16.8px] "
                                      priceView="storeList"
                                    />
                                  )}
                                </p>
                              </section>
                            </section>
                            {selectedStore?.quantity > 0 && (
                              <section>
                                <p className="mb-2 text-affes-sm font-semibold leading-[20px] text-gray-800">
                                  {' '}
                                  {formatCommonMessage({ id: 'qty', defaultMessage: 'Qty' })}:
                                </p>
                                {isPDP ? (
                                  <PdpQuantityDropdown
                                    quantity={quantity}
                                    setQuantity={setQuantity}
                                    maxQty={maxQuantityForDropDown}
                                  />
                                ) : (
                                  <QuantityDropdown
                                    updateQuantity={editItemQuantity}
                                    lineItem={data}
                                    itemQty={itemQty}
                                    setIsLoad={setIsLoad}
                                    selectedStore={selectedStore}
                                    isChangeStore={true}
                                    dropdownSelectedValue={dropdownSelectedValue}
                                    setDropdownSelectedValue={setDropdownSelectedValue}
                                  />
                                )}
                              </section>
                            )}
                          </section>
                        </section>
                        {isItemNotAvailbleToSelectedStore &&
                          currentStoreList?.filter((store) => store?.isShipToEligible && store?.isOnStock).length >
                            2 && (
                            <p className="text-[14px] text-red-900" aria-labelledby="BOPIS Item available stores">
                              {availableStoreForBOPISMsg}
                            </p>
                          )}
                        <section className="searchForm">
                          <label
                            htmlFor="search"
                            className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            {formatCommonMessage({ id: 'search', defaultMessage: 'Search' })}
                          </label>
                          <section className="relative mb-2">
                            <input
                              type="text"
                              id="search"
                              className="block w-full rounded-sm border border-gray-300 px-3 py-2.5 text-sm text-gray-900 dark:border-gray-600 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                              placeholder="City/State or Base Name"
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyDown={handleKeyDown}
                              maxLength={30}
                              required
                              aria-expanded="false"
                            />
                            <button
                              type="button"
                              className="absolute right-1.5 bottom-1 px-4 py-2 text-sm font-medium text-white dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => searchStores()}
                              aria-label="Search Button"
                            >
                              <SearchIcon />
                            </button>
                          </section>
                          {zipError && (
                            <p id="zipError" className="text-[14px] text-red-900" aria-labelledby="zipErrorMessage">
                              {zipError}
                            </p>
                          )}
                        </section>
                      </section>

                      <section className="storesList max-h-80 overflow-y-auto">
                        {currentStoreList
                          ?.map((store, index) =>
                            index === 0 ? store : store?.isShipToEligible && store?.isOnStock ? store : null,
                          )
                          .filter(Boolean)
                          ?.map((store, index) => (
                            <>
                              <section
                                key={store.id}
                                onClick={() => getStorePriceForPickup(store)}
                                onKeyDown={handleKeyDown}
                                className={`border-grey-500 ${index === 0 && 'border-t'} border-b p-2 ${
                                  selectedStore?.key === store?.key && 'border border-blue-900'
                                }`}
                              >
                                {index === 0 && (
                                  <p className="mb-2 flex items-center text-affes-sm leading-5 text-gray-800">
                                    <StarIcon className="mr-3" />
                                    {formatCartMessage({ id: 'myStore', defaultMessage: 'My Store' })}
                                  </p>
                                )}
                                <StoreTile store={store} />
                              </section>
                            </>
                          ))}
                      </section>
                      <section className="sticky bottom-0 bg-white pb-4 text-right">
                        {true && (
                          <button
                            className={classNames(
                              'mt-3 h-11 w-[7rem] rounded text-affes-sm font-bold leading-[16.8px] text-white ',
                              !isCTADisabled ? 'bg-blue-900 ' : 'bg-gray-400',
                            )}
                            disabled={isCTADisabled}
                            onClick={() => selectStoreForPickup(selectedStore?.key)}
                            aria-label={!pickUp ? 'Add To Cart' : 'Pick Up Here'}
                          >
                            {!pickUp
                              ? formatCartMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })
                              : formatCartMessage({ id: 'pickupHere', defaultMessage: 'Pick Up Here' })}
                          </button>
                        )}
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ChangeStore;
